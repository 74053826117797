import React, { Component } from 'react';

import brokenLinks from '../../resources/broken-links.json';

class NotFoundHeader extends Component {
  render = () => (
    <span>
      OOPS
    </span>
  )
}

class NotFoundBody extends Component {
  constructor(props) {
    super(props);

    this.intervalId = undefined;

    this.state = {
      replacedLinks: brokenLinks,
      modra: 'lightblue',
      pathName: undefined,
    };
  }

 
  renderPhilosophicalMessage = () => <React.Fragment>
    <h2>Nevím co tu hledáš.</h2>
    <h3>Nic tu totiž není...</h3>
    <h4>Jen můj instagram: <a href="https://instagram.com/playtoncz">playtoncz</a></h4>
  </React.Fragment>;

  

  render = () => {
    const { modra, pathName, replacedLinks } = this.state;

    const replacedLink = replacedLinks.find(item => item.from === pathName);

    return (<React.Fragment>
      <h1 style={ { textAlign: 'center', color: modra } }>404</h1>

      {
        replacedLink === undefined
          ? this.renderPhilosophicalMessage()
          : this.renderRedirectHint(replacedLink.to)
      }
    </React.Fragment>
    );
  }
}

export { NotFoundHeader, NotFoundBody };
