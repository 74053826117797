import React, { Component } from 'react';
import { Tooltip } from 'react95';

import aboutIcon from '../../resources/noveIkony/omne.svg';
import avatarPicture from '../../resources/noveIkony/logo.png';
import avatarPicturePreview from '../../resources/noveIkony/logo.png';

import './About.css';

class AboutHeader extends Component {
  render = () => (
    <span>
      <img src={ aboutIcon } alt='about' style={ { height: '15px' } }/> O mně
    </span>
  )
}

class AboutBody extends Component {
  state = {
    timePassed: '',
    myAvatarImgLoaded: false,
  };

  getDateDiff = () => {
    const timeThen = new Date(2003, 6, 24);
    const timeNow = new Date();
    const yearsDifference = timeNow.getFullYear() - timeThen.getFullYear();

    const timePassed = `${yearsDifference} let`;
    this.setState({ timePassed });
  }

  componentDidMount() {
    this.getDateDiff();
  }

  render = () => {
    const { timePassed, myAvatarImgLoaded } = this.state;

    return (
      <div className='text-container'>
        <img
          src={ avatarPicturePreview }
          alt='my avatar'
          className='avatar-picture'
          style={ { display: myAvatarImgLoaded ? 'none' : 'block' } }
        />
        <img
          src={ avatarPicture }
          alt='Logo'
          className='avatar-picture'
          style={ { display: myAvatarImgLoaded ? 'block' : 'none' } }
          onLoad={ () => { this.setState({ myAvatarImgLoaded: true }); } }
        />
        <span style={ { fontWeight: 'bold', fontSize: '2em' } }>Čau!</span>
        <p>
          Jsem <b>Tonda</b>, <br />
         na internetu jsem vystupoval jako <b>PlayTon</b>. A je mi { timePassed }.

        </p>
        <p>
          Narodil jsem se v Havířově, kde doteď žiju. Jsem student 2. ročníku Obchodně-podnikatelské fakulty na Slezské univerzitě v Karviné. 
        </p>
        <p>
          Svůj volný čas rád trávím v obklopení svých oblíbených kamarádů a buď někde kalím, nebo dělám nějakou jinou blbost. 
        </p>
        <p>
          Mou vášní jsou samozřejmě počítače a jakékoliv moderní technologie. Aktuálně pracuji jako IT Specialista ve firmě <a href='http://www.a123systems.com'>A123 Systems</a>.
        </p>
      </div>
    );
  }
}

export { AboutHeader, AboutBody };
