import React, { Component } from 'react';
import './OSInfoWindow.css';
import logo from '../../resources/noveIkony/logo.png';
import verze from '../../resources/posledni-aktualizace.json'

class OSInfoWindowHeader extends Component {
  render = () => (
    <span>
      PLAYTON.CZ
    </span>
  )
}

class OSInfoWindowBody extends Component {
 

  render = () => (<div className='main-os-logo-div'>
        <img src={ logo } alt='logo' height='130px' />
   
    <div className='os-title'>
      PlayTon.cz
    </div>
    <div className='os-subtitle'>Osobní stránky - Trošku jinak?</div>
    <div className='os-version'>Verze: {verze.buildNumber}</div>
    <div className='os-copyright'>
      &copy; 2021-{new Date().getFullYear()} PlayTon<br />
      Žádná práva vyhrazena. 
    </div>
  </div>);
}

export { OSInfoWindowHeader, OSInfoWindowBody };
