import React, { Component } from 'react';
import {
  Cutout, Button, Progress, Anchor,
} from 'react95';
import ReactMarkdown from 'react-markdown/with-html';
import WPAPI from 'wpapi';

import Util from '../Util';
import configUrls from '../../resources/config-urls.json';
import './Blog.css';

import directlinkIcon from '../../resources/icons/directlink.gif';
import hyperlinkIcon from '../../resources/icons/hyperlink.gif';
import prevArrowIcon from '../../resources/icons/prev_white.gif';
import prevArrowBlueIcon from '../../resources/icons/prev_blue.gif';
import nextArrowIcon from '../../resources/icons/next_white.gif';
import nextArrowBlueIcon from '../../resources/icons/next_blue.gif';

import blogIcon from '../../resources/noveIkony/blog.svg';
import { parse, stringify } from 'ini';

class BlogHeader extends Component {
  render = () => (
    <span>
      <img src={ blogIcon } alt='main logo' style={ { height: '15px' } }/> Příspěvky
    </span>
  )
}

class BlogBody extends Component {
  constructor(props) {
    super(props);

    this.scrollTopBlog = React.createRef();

    this.loaderInterval = undefined;
    this.hopeTimeout = undefined;

    this.state = {
      loaderInteger: 0,
      maxPostsNumber: undefined,
      currentPostIndex: undefined,
      postLoaded: undefined,
      backendResponse: undefined,
      headerText: 'STAHUJI PŘÍSPĚVKY',
    };
  }

  componentDidMount = () => {

    
    this.loaderInterval = setInterval(this.increaseLoader, 20);
    

    fetch(`${configUrls.backendUrl}/wp-json/wp/v2/posts/493`)
      .then(response => response.json())
      .then((data) => {
        console.log(data.id);
        console.log(data.content);

        this.setState({
          postLoaded: true,
          backendResponse: data.content,
          maxPostsNumber: data.id,
          currentPostIndex: data.id,
          
        });
        
      }).catch((errorObject) => {
        this.setState({
          postLoaded: false,
          backendResponse: errorObject,
        });
      });
    
  
       }
    
 
  

  componentWillUnmount = () => {
    if (this.loaderInterval !== undefined) {
      clearInterval(this.loaderInterval);
    }

    if (this.hopeTimeout !== undefined) {
      clearTimeout(this.hopeTimeout);
    }
  }

  startDecreaseLoader = () => {
    this.setState({ headerText: 'NĚCO SE NEPOVEDLO' });
    this.loaderInterval = setInterval(this.decreaseLoader, 5);
  }

  increaseLoader = () => {
    const { loaderInteger, postLoaded } = this.state;

    const percentageStop = postLoaded === undefined ? 99 : 80;

    if (loaderInteger < percentageStop) {
      this.setState({ loaderInteger: loaderInteger + 1 });
    } else {
      clearInterval(this.loaderInterval);
      if (!postLoaded && postLoaded !== undefined) {
        this.hopeTimeout = setTimeout(this.startDecreaseLoader, 1500);
      }
    }
  }

  decreaseLoader = () => {
    const { loaderInteger } = this.state;

    if (loaderInteger > 0) {
      this.setState({ loaderInteger: loaderInteger - 1 });
    } else {
      clearInterval(this.loaderInterval);
    }
  }

  loadBlogPost = (postIndex) => {
    
    fetch(`${configUrls.backendUrl}/wp-json/wp/v2/posts/${postIndex}`)
      .then(response => response.json())
      .then((data) => {
        console.log(data.id);
        this.setState({
          currentPostIndex: postIndex,
          postLoaded: true,
          backendResponse: data.content,
        });

        if (this.scrollTopBlog.current !== null) {
          this.scrollTopBlog.current.scrollIntoView({ behavior: 'smooth' });
        }
      }).catch((errorObject) => {
        this.setState({
          postLoaded: false,
          backendResponse: errorObject,
        });
      });
  }

  previousPost = () => {
    const { currentPostIndex } = this.state;
    this.loadBlogPost(currentPostIndex - 1);
  }

  nextPost = () => {
    const { currentPostIndex } = this.state;
    this.loadBlogPost(currentPostIndex + 1);
  }

  render = () => {
    const {
      backendResponse, postLoaded, loaderInteger, headerText,
      currentPostIndex, maxPostsNumber,
    } = this.state;

    if (postLoaded === undefined) {
      return (
        <div style={ { textAlign: 'center' } }>
          <h2 style={ { marginTop: '0' } }> { headerText }</h2>
          <Progress percent={ loaderInteger } shadow={ false } />
        </div>
      );
    }

    if (!postLoaded) {
      return (
        <React.Fragment>
          <div style={ { textAlign: 'center' } }>
            <h2 style={ { marginTop: '0' } }>{ headerText }</h2>
            <Progress percent={ loaderInteger } shadow={ false } />
          </div>
          <Cutout style={ { display: loaderInteger === 0 ? 'block' : 'none', backgroundColor: 'white', marginTop: '20px' } }>
            <div style={ { padding: '15px' } }>
              <p>Chyba: { backendResponse.message } <Anchor href={ `$mailto:{configUrls.email}` } target="_blank">Odeslat e-mail o chybě?</Anchor></p>
              <br />
            </div>
          </Cutout>
        </React.Fragment>
      );
    }
    var htmlko = stringify(backendResponse)
   
    console.log(htmlko)
    var htmlko2 = htmlko.replace('rendered=',"")
    htmlko2 = htmlko2.replace('protected=false',"")
    
    console.log(htmlko2)
    const regex = /[\\]n"/miu;
    const subst = ``;
    
    // The substituted value will be contained in the result variable
    htmlko2 = htmlko2.replace(regex, subst);
    const regex2 = /"[\\]n/miu;
    const subst2 = ``;
    
    // The substituted value will be contained in the result variable
    htmlko2 = htmlko2.replace(regex2, subst2);

    return (<React.Fragment>
      <div ref={ this.scrollTopBlog } />
      <Cutout className='blog-cutout'>
        <div className='document-style'>
        <h1>Blog: </h1>
          <ReactMarkdown source={ htmlko2 } escapeHtml={ false }/> 
          <p style={{color: '#229977' }} >Psst... Příspěvky budete moci ovládat šipkami již brzy.</p>
         
          
        </div>
      </Cutout>
   
      <Cutout className='blog-footer-cut-out'>
        <div className='blog-footer-buttons'>
          <Button
            fullWidth
            onClick={ this.nextPost }
            disabled={ true }
            style={ { textDecoration: 'none' } }
          >
            <img
              src={ currentPostIndex + 1 > maxPostsNumber ? nextArrowIcon : nextArrowBlueIcon }
              className='small-icon'
              alt="left arrow"
            />
            <figcaption><b>Další</b></figcaption>
          </Button>
        </div>
        <div className='blog-footer-buttons'>
          <div className='blog-footer-center-buttons'>
            
           
          </div>
        </div>
        <div className='blog-footer-buttons'>
          <Button
            fullWidth
            onClick={ this.previousPost }
            disabled={true }
            style={ { textDecoration: 'none' } }
          >
            <figcaption><b>Zpět</b></figcaption>&nbsp;
            <img
              src={ prevArrowIcon }
              className='small-icon'
              alt="right arrow"
            />
          </Button>
        </div>
      </Cutout>
    </React.Fragment>);
  }
}

export { BlogHeader, BlogBody };
