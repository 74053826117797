import React, { Component } from 'react';
import paticka from '../../resources/posledni-aktualizace.json'
import './paticka.css'

class VerzePaticka extends Component {
  render() {
    const ukazVerzi = `${paticka.buildNumber}`;

    return (
     
        <span className='verze'>
          
         Verze: {ukazVerzi}
          </span>
        
        
        
      
    );
  }
}

export default VerzePaticka;
