import React, { Component } from 'react';
import {
  Button, Anchor, Tooltip, Fieldset, Cutout,
} from 'react95';

import configUrls from '../../resources/config-urls.json';

import contactIcon from '../../resources/noveIkony/kontakt.svg';

import githubIcon from '../../resources/noveIkony/git.svg';
import igIcon from '../../resources/noveIkony/ig.svg';
import fbIcon from '../../resources/noveIkony/fb.svg';
import snapIcon from '../../resources/noveIkony/snap.svg';
import spotifyIcon from '../../resources/noveIkony/spotify.svg';
import twitterIcon from '../../resources/noveIkony/twitter.svg'; 
import konforIcon from '../../resources/noveIkony/internet.svg'; 
import linkedinIcon from '../../resources/noveIkony/linkedin.svg'; 



import './Contact.css';

class ContactHeader extends Component {
  render = () => (
    <span>
      <img src={ contactIcon } alt='icon' style={ { height: '15px' } }/> Kontakt
    </span>
  )
}

class ContactBody extends Component {
  render = () => (<React.Fragment>
    <span>
     Můžete mě sledovat na těhle všech sociálních sítích! <i>Hustý, co?</i>
    </span>
    <div className='social-icons-container'>
      <Tooltip text='INSTAGRAM' delay={ 100 }>
        <Anchor href={ `https://pltn.cz/ig` } target='_blank'>
          <Button className='social-icon' style={ { width: '60px', height: '60px', backgroundColor: 'white' } } size='lg' square>
            <img src={ igIcon } style={ { height: '40px' } } alt="INSTAGRAM"/>
          </Button>
        </Anchor>
      </Tooltip>
      <Tooltip text='FACEBOOK' delay={ 100 }>
        <Anchor href={ 'https://pltn.cz/fb' } target='_blank'>
          <Button className='social-icon' style={ { width: '60px', height: '60px', backgroundColor: 'white' } } size='lg' square>
            <img src={ fbIcon } style={ { height: '40px' } } alt="FB"/>
          </Button>
        </Anchor>
      </Tooltip>
      <Tooltip text='GITHUB' delay={ 100 }>
        <Anchor href='https://pltn.cz/git' target='_blank'>
          <Button className='social-icon' style={ { width: '60px', height: '60px', backgroundColor: 'white' } } size='lg' square>
            <img src={ githubIcon } style={ { height: '40px' } } alt="github"/>
          </Button>
        </Anchor>
      </Tooltip>
      <Tooltip text='TWITTER' delay={ 100 }>
        <Anchor href='https://twitter.com/tondaplayton' target='_blank'>
          <Button className='social-icon' style={ { width: '60px', height: '60px', backgroundColor: 'white' } } size='lg' square>
            <img src={ twitterIcon } style={ { height: '30px' } } alt="twitter"/>
          </Button>
        </Anchor>
      </Tooltip>
      <Tooltip text='SNAPCHAT' delay={ 100 }>
        <Anchor href='https://pltn.cz/snap/' target='_blank'>
          <Button className='social-icon' style={ { width: '60px', height: '60px', backgroundColor: 'white' } } size='lg' square>
            <img src={ snapIcon } style={ { height: '40px' } } alt="SNAPCHAT"/>
          </Button>
        </Anchor>
      </Tooltip>
      <Tooltip text='SPOTIFY' delay={ 100 }>
        <Anchor href='https://pltn.cz/spotify/' target='_blank'>
          <Button className='social-icon' style={ { width: '60px', height: '60px', backgroundColor: 'white' } } size='lg' square>
            <img src={ spotifyIcon } style={ { height: '40px' } } alt="SPOTIFY"/>
          </Button>
        </Anchor>
      </Tooltip>
      <Tooltip text='KONTAKTNÍ FORMULÁŘ - JIŽ BRZY :O' delay={ 100 }>
        <Anchor href='' target='_blank'>
          <Button className='social-icon' style={ { width: '60px', height: '60px', backgroundColor: 'white' } } size='lg' square>
            <img src={ konforIcon } style={ { height: '40px' } } alt="KONTAKTNÍ FORMULÁŘ"/>
          </Button>
        </Anchor>
      </Tooltip>
      <Tooltip text='LINKEDIN' delay={ 100 }>
        <Anchor href='https://www.linkedin.com/in/anton%C3%ADn-pavl%C3%ADk-b838a31a6/' target='_blank'>
          <Button className='social-icon' style={ { width: '60px', height: '60px', backgroundColor: 'white' } } size='lg' square>
            <img src={ linkedinIcon } style={ { height: '40px' } } alt="LINKEDIN"/>
          </Button>
        </Anchor>
      </Tooltip>
    </div>
    <div className='contact-info'>
      <Fieldset label={ <img src={ contactIcon } style={ { height: '20px' } } alt="question mark"/> }>
        <div style={ { paddingBottom: '15px' } }>
          <span>
            A nebo mě můžete klasicky kontaktovat na emailu:
          </span>
        </div>
        <Cutout className='fingerprint-cutout' style={ { padding: '10px' } }>
          <div className='fingerprint-container'>
            <Anchor href={ `mailto:${configUrls.email}?subject=E-Mail%2Z%20Kontaktniho%20Formulare` } target='_blank'>
            <span>{'kontakt@playton.cz'}</span>
            </Anchor>
          </div>
        </Cutout>
      </Fieldset>
    </div>
  </React.Fragment>)
}

export { ContactHeader, ContactBody };
