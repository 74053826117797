const theme = {
  hatchedBackground:
    'url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAYAAACp8Z5+AAAAIElEQVQYV2P8////fwYGBgZGRkZGMI0hABIFAbgEugAAQFQP/QfjEPcAAAAASUVORK5CYII=)',

  // background: '#CCF5AC',
  // background: '#416165',
  background: '#4C6663',

  canvas: '#c7c7df',
  material: 'white',
  materialDark: '#6063a5',

  borderDarkest: '#009dff',
  borderLightest: '#009dff',
  borderDark: '#009dff',
  borderLight: '#009dff',

  headerMaterialDark: '#009dff',
  headerMaterialLight: '#009dff',
  headerText: 'white',

  text: 'black',
  textInvert: 'white',
  textDisabled: 'white',
  textDisabledShadow: 'white',

  inputText: '#050608',
  inputTextInvert: '#ffffff',
  inputTextDisabled: '#888c8f',
  inputTextDisabledShadow: '#ffffff',

  tooltip: 'white',

  anchor: 'white',
  anchorVisited: '#440381',

  hoverBackground: '#009dff',
  checkmark: '#010601',
  progress: '#009dff',

  flatLight: '#a4a7c8',
  flatDark: '#5b57a1',
};

export default theme;
