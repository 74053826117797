import React, { Component } from 'react';
import { Cutout } from 'react95';
import _ from 'lodash';
import SoundEffects from '../additional/SoundEffects';
import LoaderCursor from '../additional/LoaderCursor';
import IkonaProjekty from '../../resources/noveIkony/projekty.svg';
import projectsList from '../../resources/list-projektu.json';

import './Projects.css';

const PROMPT_CHARS = ['>', '$', '#', ']', 'é', 'ZX'];

class ProjectsHeader extends Component {
  render = () => (
    <span>
      <img src={ IkonaProjekty } alt='Projekty' style={ { height: '15px' } }/> Projekty
    </span>
  )
}

class CmdLoader extends Component {
  constructor(props) {
    super(props);

    this.updaterInterval = undefined;

    this.state = {
      index: 0,
      sequence: ['|', '/', '-', '\\'],
    };
  }

  componentDidMount() {
    this.updaterInterval = setInterval(this.characterUpdater, 100);
  }

  componentWillUnmount() {
    if (this.updaterInterval) {
      clearInterval(this.updaterInterval);
    }
  }

  characterUpdater = () => {
    this.setState({ index: this.state.index + 1 });
  }

  render() {
    const { index, sequence } = this.state;
    const { isLoading } = this.props;

    if (!isLoading) {
      return null;
    }

    return <React.Fragment>
      <span>{sequence[index % sequence.length]}</span>
      <LoaderCursor />
    </React.Fragment>;
  }
}

class ProjectsBody extends Component {
  state = {
    showPrompt: false,
    shellOutput: undefined,
    stdError: false,
    shellWaiting: false,
    randomPromptChars: Object.keys(PROMPT_CHARS).map(e => PROMPT_CHARS[e])[
      Math.floor(Math.random() * Object.keys(PROMPT_CHARS).map(e => PROMPT_CHARS[e]).length)
    ],
  }

  componentDidMount() {
    document.addEventListener('keydown', this.enterPressed);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.enterPressed);
  }

  enterPressed = (event) => {
    const { showPrompt } = this.state;

    const commands = {};

    commands.help = () => {
      this.setState({ shellOutput: 'Juj, to bys chtěl vědět, co?' });
    };

    commands.ls = () => {
      this.setState({ shellOutput: '... Co? Jaký asi jiný seznam tu může být?' });
    };

    commands.dir = () => {
      this.setState({ shellOutput: 'mhm, uživatel windowsu...' });
    };

    commands.whoami = () => {
      this.setState({ shellOutput: navigator.userAgent });
    };

    commands.man = () => {
      this.setState({ shellOutput: 'woman' });
    };

   

    commands.passwd = () => {
      this.setState({ shellOutput: '1234' });
    };

    commands.ahoj = () => {
      this.setState({ shellOutput: 'Ahoooj' });
    };

    commands.sudo = (args) => {
      if (_.isEmpty(args)) {
        this.setState({ shellOutput: 'Zadej příkaz sudo + heslo '});
      } else if (args == '1234') {
        this.setState({ shellOutput: 'Otvírám administrační konzoli', shellWaiting: true });
        window.location.href = 'http://su.playton.cz/zwebu/';
      }
      else {
        this.setState({ shellOutput: 'jujky nesprávné heslo '});
      }
    };

    commands.clear = () => {
      this.setState({ shellOutput: undefined });
    };

   

    commands.reboot = () => {
      const { poweroffSound } = SoundEffects;
      poweroffSound.load();
      poweroffSound.play();
      this.setState({ shellOutput: 'Restartuji systém...', shellWaiting: true });
      poweroffSound.on('end', () => {
        window.location.href = '/';
      });
    };

    commands.vim = () => {
      this.setState({ shellOutput: 'Spouštím VIM...', shellWaiting: true });
      window.location.href = 'https://www.openvim.com/sandbox.html';
    };

    

    commands.format = () => {
      localStorage.clear();
      sessionStorage.clear();

      this.setState({ shellOutput: 'Cache stránek byla vymazána', shellWaiting: true });

      setTimeout(() => {
        commands.reboot();
      }, 1500);
    };

    commands.shutdown = () => {
      const { poweroff } = this.props;

      poweroff();
    };

    commands.exit = () => {
      const { isFullscreen, closeCurrentWindow } = this.props;

     
        closeCurrentWindow();
      
    };

    if (showPrompt && event.keyCode === 13) {
      const cmdString = document.getElementById('promptText').innerText.trim().split(' ');

      if (_.isEmpty(cmdString[0])) {
        commands.clear();
        this.setState({ stdError: false });
      } else if (Object.keys(commands).includes(cmdString[0].toLowerCase())) {
        commands[cmdString[0].toLowerCase()](cmdString.slice(1));
        this.setState({ stdError: false });
      } else {
        this.setState({ shellOutput: 'PŘÍKAZ NEEXISTUJE', stdError: true });
      }

      const promptTextDiv = document.getElementById('promptText');

      if (promptTextDiv !== null) {
        promptTextDiv.innerText = '';
      }

      event.preventDefault();
    }
  }

  renderProjectsList = () => projectsList.map((item, index) => (<div key={ `${item.name}_${index}` } className='project-item'>
    <span className='bulletpoint'>#</span>
    <span> <a href={ item.url } className='project-item' target='_blank' rel='noopener noreferrer'>{ item.name }</a>
      
        <span className='bulletpoint'> ─ </span> { item.description }
    
    </span>
  </div>));

  focusPrompt = () => {
    this.setState({ showPrompt: true });
    document.getElementById('promptText').innerText = '';
    document.getElementById('promptText').focus();
  }

  render = () => {
    const {
      randomPromptChars, showPrompt, shellOutput, stdError, shellWaiting,
    } = this.state;

    const isZXSpectrum = randomPromptChars === 'ZX';

    return (<React.Fragment>
      <Cutout className='cutout-area' style={ { padding: '10px' } }>
        <div className='comment-text' style={ { paddingBottom: '15px' } }>{'//'} Tohle jsou mé (dokončené) projekty!</div>
        {this.renderProjectsList()}
        <br />
        <div style={ { paddingBottom: '15px', display: shellOutput !== undefined ? 'inline-block' : 'none', color: stdError ? 'red' : 'lime' } }>
          <span style={ { color: 'red' } }>{ stdError ? '[!]' : '=>' } </span>
          { shellOutput }
          <span style={ { display: shellWaiting ? 'inline-block' : 'none' } }>&nbsp;<CmdLoader isLoading={ shellWaiting } /></span>
        </div>
        <div
          style={ { display: shellWaiting ? 'none' : 'block' } }
          onClick={ this.focusPrompt }
          className={ `prompt-area ${showPrompt ? '' : 'animated bounce fast delay-5s'}` }
        >{isZXSpectrum ? '' : `${randomPromptChars} `}
          <div id='promptText' style={ {
            display: 'inline-block',
            caretColor: 'transparent',
            outline: 'none',
            border: 'none',
          } } contentEditable='true'></div>
          <span className={ showPrompt ? 'blink' : '' }>{isZXSpectrum ? '🄺' : '█'}</span>
        </div>
      </Cutout>
    </React.Fragment>);
  }
}

export { ProjectsHeader, ProjectsBody };
