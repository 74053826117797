const theme = {
  hatchedBackground:
    'url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAYAAACp8Z5+AAAAIElEQVQYV2P8////fwYGBgZGRkZGMI0hABIFAbgEugAAQFQP/QfjEPcAAAAASUVORK5CYII=)',

  canvas: '#ffffff',
  material: 'white',
  materialDark: '#181818',

  borderDarkest: 'white',
  borderLightest: 'white',
  borderDark: 'white',
  borderLight: 'white',

  headerMaterialDark: '#009dff',
  headerMaterialLight: '#009dff',
  headerText: '#ffffff',

  text: '#050608',
  textInvert: '#ffffff',
  textDisabled: '#888c8f',
  textDisabledShadow: '#ffffff',

  inputText: '#050608',
  inputTextInvert: '#ffffff',
  inputTextDisabled: '#888c8f',
  inputTextDisabledShadow: '#ffffff',

  tooltip: '#fefbcc',

  anchor: '#1034a6',
  anchorVisited: '#440381',

  hoverBackground: '#000080',
  checkmark: '#050608',
  progress: '#000080',

  flatLight: 'lightblue',
  flatDark: 'skyblue',
};

export default theme;
