import React, { Component } from 'react';

import warningIcon from '../../resources/noveIkony/nastvany.svg';
import checkIcon from '../../resources/icons/check.png';

import './Fix.css';

class FixHeader extends Component {
  render = () => (
    <span>
      LOL
    </span>
  )
}

class FixBody extends Component {
  // eslint-disable-next-line class-methods-use-this
  componentDidMount() {
    
  }

  renderFixed = () => <span className='message-container'>
    <img src={ checkIcon } style={ { height: '52px' } } alt='success icon' /><h1>The computer has been fixed...</h1>
  </span>

  renderWarning = () => <span className='message-container'>
    <img src={ warningIcon } style={ { height: '52px' } } alt='warning icon' /><h1>Nebuď netrpělivý!</h1><br></br><h2>Říkal jsem již brzy...</h2>
  </span>

  render = () => (<React.Fragment>
    { localStorage.getItem('fixed') ? this.renderFixed() : this.renderWarning() }
  </React.Fragment>
  )
}

export { FixHeader, FixBody };
