import React, { Component } from 'react';
import {
  TabBody, Tab, Tabs, Cutout, Tooltip,
} from 'react95';

import Util from '../Util';
import cookieIcon from '../../resources/icons/cookie.gif';
import blackCursor from '../../resources/icons/pointers/cursor.gif';

import './Credits.css';

class CreditsHeader extends Component {
  render = () => (
    <span>
      Jak?
    </span>
  )
}

class CreditsBody extends Component {
  state = {
    activeTab: 0,
  }

  componentDidMount() {
   
  }

  handleChangeTab = value => this.setState({ activeTab: value });

  render = () => {
    const { activeTab, codeAnimation } = this.state;

   

    return (<div className='credits-window'>
      <div>
        Stránka byla vytvořena pomocí <a href='https://reactjs.org' target='_blank' rel='noopener noreferrer'>Reactu.</a> Pokud je tahle stránka pro tvůj počítač moc nóbl, vypni javascript a přejdeš do  <i>nouzového režimu.</i>
        <p>
         Stránka bude v budoucnu dostupná na githubu
        </p>
      </div>
      <Tabs value={ activeTab } onChange={ this.handleChangeTab } style={ { paddingTop: '10px' } }>
        <Tab style={ { cursor: `url(${blackCursor}), auto` } } value={ 0 }>Práva</Tab>
        <Tab style={ { cursor: `url(${blackCursor}), auto` } } value={ 1 }>Kód</Tab>
        <Tab style={ { cursor: `url(${blackCursor}), auto` } } value={ 2 }>Lidi</Tab>
        <Tab style={ { cursor: `url(${blackCursor}), auto` } } value={ 3 }>Odkazy</Tab>
        <Tab style={ { cursor: `url(${blackCursor}), auto` } } value={ 4 }>Zvuky</Tab>
        <Tab style={ { cursor: `url(${blackCursor}), auto` } } value={ 5 }><img height='15px' src={ cookieIcon } alt='cookie' />&nbsp;Cookies</Tab>
      </Tabs>
      <TabBody>
        <div style={ { marginTop: '-5px' } }>
          <Cutout style={ { backgroundColor: '#e9e8ff' } } shadow={ false }>
            <ul style={ { display: activeTab === 0 ? 'block' : 'none' } }>
              <li className='list-content'>Všechny <b>ikony</b> jsou z fontu FontAwesome</li>
              <li className='list-content'>Zdrojový kód je pod MIT licencí.</li>
            </ul>
            <ul style={ { display: activeTab === 1 ? 'block' : 'none' } }>
              <li className='list-content'><a href='https://github.com/arturbien/React95' target='_blank' rel='noopener noreferrer'>React95</a> → Windows 95 styl UI pro React</li>
              <li className='list-content'><a href='https://daneden.github.io/animate.css/' target='_blank' rel='noopener noreferrer'>Animate.css</a> → Animace použita pro různé účely</li>
              <li className='list-content'><a href='https://www.npmjs.com/package/react-draggable' target='_blank' rel='noopener noreferrer'>react-draggable</a> → Použití drag & drop v reactu</li>
            </ul>
            <ul style={ { display: activeTab === 2 ? 'block' : 'none' } }>
              <li className='list-content'>Michael Nový → Pomoc při odstranění znaků, které byli trošku navíc, při fetchování z WP API</li>
              <li className='list-content'>Andrzej Grabowski → Pomoc při nadávání při tom, co něco nefungovalo</li>
              <li className='list-content'> Simone Marzulli → Inspirace z jeho projektu</li>
            </ul>
            <ul style={ { display: activeTab === 3 ? 'block' : 'none' } }>
              <li className='list-content'>
              Tato stránka není přidružena, podporována ani žádným způsobem oficiálně připojena (kromě hypertextových odkazů lol) k webovým stránkám uvedeným na stránce Odkazy.
              </li>
            </ul>
            <ul style={ { display: activeTab === 4 ? 'block' : 'none' } }>
              <li className='list-content'>Zvuk vypínání je nasamplovaný z této písničky: <a href='https://www.youtube.com/watch?v=Wl-Xgv7X4ME' target='_blank' rel='noopener noreferrer'>Grace Jones - Slave to the Rhythm</a></li>
            </ul>
            <ul style={ { display: activeTab === 5 ? 'block' : 'none' } }>
              <li className='list-content'>Tato stránka používá <a href='https://statcounter.com' target='_blank' rel='noopener noreferrer'>statcounter.com</a> k získání informací jako <b>návštěvnost</b>, <b>země původu, stránky které jsi navštívil </b>a které <b>stránky mě využili</b>.</li>
              <li className='list-content'>Pokud nesouhlasíš s "cookie policy" doporučuji stáhnout něco jako <a href='https://en.wikipedia.org/wiki/UBlock_Origin' target='_blank' rel='noopener noreferrer'>uBlock Origin</a> a <a href='https://en.wikipedia.org/wiki/Privacy_Badger' target='_blank' rel='noopener noreferrer'>Privacy Badger</a>, nebo zmáčkni CTRL + F4 a běz spát.</li>
            </ul>
          </Cutout>
        </div>
      </TabBody>
      <div style={ { paddingTop: '15px', textAlign: 'center' } }>
        
      </div>
    </div>);
  }
}

export { CreditsHeader, CreditsBody };
