//Jujky
import React, { Component } from 'react';
import {
  Button, Cutout, Anchor,
} from 'react95';
import errorIcon from '../../resources/icons/error.png';
import './Gabriele.css';
import SoundEffects from '../additional/SoundEffects';
import configUrls from '../../resources/config-urls.json';

class GabrieleHeader extends Component {
  render = () => <span>Chybička</span>
}

class GabrieleBody extends Component {
  state = {
    showReport: false,
  }

  // eslint-disable-next-line class-methods-use-this
  componentDidMount() {
    SoundEffects.errorSound.load();
    SoundEffects.errorSound.play();
  }

  toggleReport = () => { this.setState({ showReport: true }); }

  render = () => {
    const { closeCurrentWindow } = this.props;
    const { showReport } = this.state;

    return (<React.Fragment>
      <div className='header-error'>
        <span>
          <img
            src={ errorIcon }
            alt='trembling error'
            className='error-icon shake'
          />
          PlayTon.cz zjistil problém.
        </span>
      </div>
      <div className='useless-error-message'>
        <p>
         pokud na něčem pracujete, bohužel už pracovat nebudete.
        </p>
        <p>
          <span style={ { fontWeight: 'bolder' } }>Prosím, řekněte svému nadřízenému, že jste sexista.</span><br />
          <span>
           Vytvořili jsme zprávu o chybě, abychom mohli zlepšit PlayTon.cz stránku. S touto zprávou budeme zacházet opatrně a data budou odeslány anonymně.
          </span>
        </p>
        <p>
         Pokud chcete vědět, co je špatně, <span className='error-report-link' onClick={ this.toggleReport }>kliknte zde.</span></p>
      </div>
      <Cutout style={ { display: showReport ? 'block' : 'none' } } className='error-cutout'>
        <div className='error-report-space'>
          <p>Line: 69<br />Error: 'EasterEggException' <Anchor href={ `${configUrls.repositoryUrl}/tree/master/src/components/windows/Chyba.js#L69` } target="_blank">@ Chyba.js</Anchor></p>
          <br />
          <br />
        </div>
      </Cutout>
      <div className='bottom-buttons'>
        <Anchor
          href='https://su.playton.cz/chyba/homo'
          style={ { color: '#000000', textDecoration: 'none' } }
        >
          <Button
            style={ { width: '160px' } }
            size='md'
          ><span className='underline-text'>O</span>deslat chybu</Button>
        </Anchor>
        <Button
          style={ { marginLeft: '10px', width: '120px' } }
          size='md'
          onClick={ closeCurrentWindow }
        ><span className='underline-text'>N</span>eodesílat</Button>
      </div>
    </React.Fragment>
    );
  }
}

export { GabrieleHeader, GabrieleBody };
